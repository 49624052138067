@import "../settings";

.Footer {
    bottom: 0; 
    width: 100%;
    display: flex;
    background-color: $color-2;
    padding-top: 50px;
    padding-bottom: 50px;
    color:$white;

    a {
        color:$white;
        text-decoration: none;
        font-weight: 600;
    }

}