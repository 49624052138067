/*DECLARATION VARIABLE CSS*/

/*FONT*/

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 800

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 800




$fontFamily: Arial, Helvetica, sans-serif;
$fontWeight: 400;

/*COLOR*/
$color-1: #ed5436;
$color-2: #002d4f;
$color-3: #000000;
$color-4:#333333;
$white: rgb(243, 243, 243);
$color-hover:#ed5436;

/* H1*/
$h1FontSize: 50px;
$h1Color: $color-2;
$h1FontWeight:600;
$h1TextAlign:center;
$h1TextTransf:uppercase;
$h1SpanColor:$color-1;

/*H2*/
$h2FontSize: 40px;
$h2Color: $color-1;
$h2FontWeight:600;

/*H3*/
$h3FontSize: 30px;
$h3Color: $color-2;
$h3FontWeight:600;
$h3TextTransform:uppercase;

/*H4*/
$h4FontSize: 24px;
$h4Color: $color-2;
$h4FontWeight:600;

/*LI*/
$liColor: $color-3;
$liFontWeight:500;
$liSpanColor:$color-2;
$liSpanFontSize: 24px;

/*VARIABLE SANS PUCES UL SPAN*/
$sansPucesUlListStyle:none;
$sansPucesSpanFontSize:24px;

/*VARIABLE BODY - LAYOUT*/
$bodyDisplay:flex;
$bodyFlexDirection:column;
$bodyMargin: 0px;
$bodyMinHeight: 100vh;


/*VARIABLE BOUTON*/
$boutonBg:$color-1;
$boutonPadding:10px;
$boutonMargin:20px;
$boutonBorder:none;
$boutonRadius:50px;
$boutonCursor:pointer;
$boutonAColor:$white;
$boutonATextDeco:none;
$boutonAFontWeight:600;
$boutonAFontsize:20px;


@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,.Layout {
  display: $bodyDisplay;
  flex-direction: $bodyFlexDirection;
  min-height: $bodyMinHeight;
  background: $white;
  font-family: $fontFamily;
  font-weight: $fontWeight;
  font-size:18px;
  color: $color-3;
}

h1{
  font-size:$h1FontSize;
  color: $h1Color;
  font-weight: $h1FontWeight;
  text-align: $h1TextAlign;
  text-transform: $h1TextTransf;
  span {
    color:$h1SpanColor;
}
}

h2{
  font-size:$h2FontSize;
  color: $h2Color;
  font-weight: $h2FontWeight;
}

h3{
  font-size:$h3FontSize;
  color: $h3Color;
  font-weight: $h3FontWeight;
  text-transform: $h3TextTransform;
}

h4{
  font-size:$h4FontSize;
  color: $h4Color;
  font-weight: $h4FontWeight;
}

li {
  span{
    color:$liSpanColor;
    font-size: $liSpanFontSize;
  }
}
/*CSS SANS PUCES*/
.sans-puces {
  ul {
      list-style: $sansPucesUlListStyle;
      span {
          font-size:$sansPucesSpanFontSize;
      }
  }
}

/*CSS BOUTON*/

.bouton {
  background-color:$boutonBg;
  padding: $boutonPadding;
  margin: $boutonMargin;
  border: $boutonBorder;
  border-radius: $boutonRadius;
  &:hover {
      background-color: $color-2;
      cursor: $boutonCursor;
  }
  a{
      color:$boutonAColor;
      text-decoration: $boutonATextDeco;
      font-weight: $boutonAFontWeight;
      font-size: $boutonAFontsize;
  }
}
.bouton-retour {
  background-color: $color-2;
  &:hover {
    background-color: $color-1;
    cursor: $boutonCursor;
}
}

.card {
  display: flex;
  flex-direction: column;
  width: 300px; // Ajuste la largeur selon tes besoins
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .card-image {
    width: 100%;
    height: 200px; /* Ajuste la hauteur selon tes besoins */
    object-fit: cover;
    object-position: center;
  }
  

  .card-title {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .card-content {
    display: flex; /* Utilise flex pour centrer les éléments */
    flex-wrap: wrap; /* Permet de gérer les éléments sur plusieurs lignes */
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    padding: 16px; /* Ajoute un peu de padding pour l'espacement */

    .colonne {
      display: flex;
      flex-direction: column;
      justify-content: center; /* Centre verticalement les colonnes dans la ligne */
      align-items: center; /* Centre horizontalement les éléments dans la colonne */
      padding: 8px;

      .cell {
        text-align: center;
        padding: 8px;
        background-color: $color-2;
        border-radius: 20px;
        color: $white;
        margin: 8px; /* Ajoute un peu d'espace entre les cellules */
      }
    }
  }

  .card-button {
    height: 70px;
    padding: 12px;
    background-color: $color-1;
    color: white;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border: none;
    border-top: 1px solid #ccc;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color-2;
      font-size: 26px;
    }
  }
}


.separator{
  width: 300px;
  height: 5px;
  background-color: #ed5436;
  margin-bottom: 20px; /* Espacement au-dessus et en dessous du séparateur */
}

.pills {
  display: wrap;
  text-align: center;
  padding: 5px 20px 5px 20px;
  background-color: $color-2;
  border-radius: 15px;
  color:$white;
  width:100%;
}

.styled-text { 
  text-align: center; 
  color: #707070; 
}

.input-text {
  text-align: center;
  color: $color-4;
  font-size: 1em;
}

