@import "../settings";

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.a-nav-link {
    color: $white;
    text-decoration: none;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.full-height-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.h-100 {
    height: 100%;
}
