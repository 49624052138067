@import "../settings";

/*HEADER*/


.HomeHeader {
    display: flex;
    background-color: $white;
    margin-top:20px;
    margin-bottom: 50px;

}
/*SECTION 1*/
.SectionBgColor-1{
    background-color: $color-1;
}

.HomeSection-1 {
    background-color: $color-1;
    color: $white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;

    ul {
        list-style: $sansPucesUlListStyle;

        span {
            font-size: $sansPucesSpanFontSize;
        }
    }

    h2 {
        color: $color-2;
        text-align: center;
        margin: 20px;
    }

    img {
        padding: 15px;
    }

    // Media query for responsiveness
    @media (max-width: 1000px) {
        .row {
            flex-direction: column;

            .col {
                width: 100%;
                margin-bottom: 20px; // adjust margin as needed
            }
        }
    }
}


/*SECTION 2*/

.HomeSection-2{
    display: wrap;
    margin-top:20px;
    margin-bottom: 20px;
    padding: 10px;
    
    h3{
        text-align: center;
        margin:20px;
    }
    .ColTextAlignRight {
        text-align:right;
        margin-right:10px;
        padding-right: 15px;
        border-right:dashed;
    }
    .ColTextAlignLeft {
        text-align:left;
        margin-left:10px;
        padding-left: 15px;
        border-left:dashed;
    }
    .separatorCenter{
        display: flex;
        justify-content: center; /* Centre horizontalement */
        align-items: center;     /* Centre verticalement */
        flex-direction: column;  /* Aligne les éléments en colonne */
    }
}
