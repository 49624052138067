// Variables d'import
@import "../settings";

// Recherche Section
.Recherche {
    .hero-content {
        margin: 0 auto;
        max-width: 100%;

        .main-search-bar {
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .form-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                // Réactivité pour les écrans de 1000px et moins
                @media (max-width: 1000px) {
                    flex-direction: column;
                    align-items: stretch;
                }

                .form-col {
                    flex: 1;
                    margin: 0.5rem 0;

                    // Réactivité pour les écrans de 1000px et moins
                    @media (max-width: 1000px) {
                        width: 100%;
                    }

                    .input-container {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .input-text {
                            width: 100%;
                            padding: 10px;
                            border: none;
                            border-radius: 20px;
                        }
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // Réactivité pour les écrans de 1000px et moins
                    @media (max-width: 1000px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// Styled Text
.styled-text {
    text-align: center;
    color: #666666;
    margin-top: 1rem;
}

// Resultat Section
.Resultat {
    .container-fluid {
        .row {
            margin: 0 auto;
        }
    }
}

// Bouton
.bouton {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: $color-1;
    color: $white;

    &:hover {
        background-color: darken($color-2, 10%);
    }
}
